#about_intro {
  @include media-breakpoint-up(md) {
    .page_intro-holder {
      background-position: left;
    }

    #about_intro-content_heading {
      justify-content: flex-end !important;

      & > div {
        justify-content: flex-start !important;
      }

      .intro_heading-holder {
        padding-right: 0;
        padding-left: 50px;
      }
    }

    #about_intro-content_copy {
      .container-fluid {
        justify-content: flex-end !important;

        & > div {
          justify-content: flex-start !important;
        }
      }

      .intro_copy-holder {
        padding-right: 0;
        padding-left: 50px;
      }
    }
  }
}

#about_team-accordion {
  padding-left: 5vw;

  @include media-breakpoint-down(md) {
    padding-right: 1rem;
    padding-left: 1rem !important;
  }

  .accordion-button {
    color: $primary;
    padding-right: 4vw;
    border-bottom: 2px solid transparent;
    align-items: stretch;

    &::after {
      display: none !important;
    }

    .agent-name {
      .active {
        opacity: 0;
        transition: 1s opacity;
      }

      .inactive {
        opacity: 1;
        transition: 1s opacity;
      }
    }

    .accordion-button-icon {
      align-items: flex-end;
      transition: 1s all;

      @include media-breakpoint-down(md) {
        align-items: flex-start;
        padding-top: 1rem;
      }

      .accordion-button-icon-holder {
        width: 60px;
        height: 30px;
        position: relative;
        overflow: hidden;
        margin-bottom: 0;
        transition: 1s all;
        transform: translateY(120%);
        background-color: $white;

        .accordion-button-icon-img {
          transition: 1s all;
          position: absolute;
          object-fit: contain;
          object-position: center;
          left: 50%;
          transform: translateX(-50%);

          &.blue {
            opacity: 0;
          }

          &.orange {
            opacity: 1;
          }
        }
      }
    }

    &.collapsed {
      .accordion-button-icon {
        opacity: 0;

        @include media-breakpoint-down(md) {
          opacity: 1;
        }

        .accordion-button-icon-holder {
          transform: rotate(45deg);
          margin-bottom: 50%;
        }
      }
    }

    &:not(.collapsed) {
      box-shadow: unset;
      background-color: transparent;
      border-bottom-color: $secondary;

      .agent-name {
        span {
          font-weight: 700 !important;
        }
      }

      .accordion-button-icon {
        .accordion-button-icon-img {
          &.blue {
            opacity: 1;
          }

          &.orange {
            opacity: 0;
          }
        }
      }
    }

    &:hover {
      color: $secondary;

      .agent-name {
        .active {
          opacity: 1;
        }

        .inactive {
          opacity: 0;
        }
      }
    }

    &.collapsed:hover {
      .accordion-button-icon {
        opacity: 1;
      }
    }

    &:not(.collapsed):hover {
      .accordion-button-icon {
        .accordion-button-icon-img {
          &.blue {
            opacity: 0;
          }

          &.orange {
            opacity: 1;
          }
        }
      }
    }
  }

  .accordion-body {
    max-width: 85vw;

    @include media-breakpoint-down(md) {
      max-width: unset !important;
    }

    .agent-headshot-img {
      border-radius: 50%;
    }
  }
}

#about_history-events_carousel {
  padding: 0 10% !important;

  @include media-breakpoint-down(md) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .slick-dots {
    padding-right: 0;
    align-items: flex-end;
    justify-content: flex-end;

    @include media-breakpoint-down(md) {
      padding-left: 5%;
      padding-right: 5%;
      align-items: center;
      justify-content: center;
    }
  }
}
