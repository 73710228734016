#reviews-grid {
  .page_reviews-grid {
    .review-block {
      blockquote {
        padding-left: 2em !important;
        font-size: 1rem !important;
        margin-bottom: 0 !important;

        &::after {
          width: 0 !important;
          height: 0 !important;
          overflow: hidden;
          visibility: hidden !important;
          font-size: 0.001px !important;
          line-height: 1px !important;
          display: inline;
        }

        &::before {
          font-family: $font-family-serif;
          color: $brand-orange;
          font-size: 3rem;
          line-height: 1em;
        }

        p {
          font-family: $font-family-sans;
          font-size: 1rem;
          color: $black;
        }
      }

      .blockquote-footer {
        .review-citation {
          width: fit-content;
          margin-left: auto;
          margin-right: 0;

          .review_cite-name {
            color: $brand-blue;
            font-size: 1.5rem;
            width: fit-content;
          }

          .review_cite-title {
            color: $brand-darkGrey;
            font-size: 0.8rem;
            width: fit-content;
          }
        }
      }
    }
  }
}
