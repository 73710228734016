/* All Sites */
$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 1024px,
  lg: 1366px,
  xl: 1540px,
  xxl: 1920px,
  xxxl: 2560px,
  xxxxl: 3840px,
);

/* Bonner Carrington */
$brand-blue: #003da5;
$brand-orange: #fa4616;
$brand-darkBlue: #041e42;
$brand-lightBlue: #009cde;
$brand-whiteBlue: #a3c6e0;
$brand-grey: #efefef;
$brand-darkGrey: #4a5053;

$primary: $brand-blue;
$secondary: $brand-orange;
$blue: $brand-blue;
$orange: $brand-orange;
$teal: $brand-lightBlue;

$font-family-literata: "Literata", serif;
$font-family-inter: "Inter", sans-serif;

$font-family-sans: $font-family-inter;
$font-family-serif: $font-family-literata;

$headings-font-family: $font-family-serif;

$font-var: "opsz" 72;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-size-base:  1rem;

$h1-font-size:  $font-size-base * 11.35;
$h2-font-size:  $font-size-base * 5;
$h3-font-size:  $font-size-base * 4;
$h4-font-size:  $font-size-base * 3.25;
$h5-font-size:  $font-size-base * 1.25;
$h6-font-size:  $font-size-base;
$nav-font-size:  $font-size-base * 2.75;
$body-font-size: $font-size-base;
$quote-font-size: $font-size-base * 2.25;
$blurb-font-size: $font-size-base * 0.8;
$cite-font-size: $font-size-base * 1.9375;
$blockquote-font-size: $quote-font-size;
$blockquote-footer-font-size: $cite-font-size;

$btn-font-size: $font-size-base * 1.4;
$btn-font-size-sm: $btn-font-size * 0.75;
$btn-font-size-lg: $btn-font-size * 1.5;
$btn-font-family: $font-family-serif;
$btn-border-width: 0;
$btn-font-weight: $font-weight-regular;
$btn-padding-x: 0;
$btn-padding-x-sm: 0;
$btn-padding-x-lg: 0;
$btn-padding-y: 0.4em;
$btn-padding-y-sm: $btn-padding-y;
$btn-padding-y-lg: $btn-padding-y;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

$headings-font-weight:  $font-weight-light;
$btn-font-weight: $font-weight-regular;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  xs: 0.6 * $font-size-base,
  sm: 0.8 * $font-size-base,
  md: $font-size-base,
  lg: 1.2 * $font-size-base,
  xl: 1.5 * $font-size-base,
  xxl: 1.85 * $font-size-base,
  xxxl: 2.5 * $font-size-base,
  quote: $quote-font-size,
  body: $body-font-size,
  blurb: $blurb-font-size,
  nav: $nav-font-size,
);

$carousel-indicator-height: var(--carouselIndicatorHeight, 3px);
$carousel-indicator-width: var(--carouselIndicatorWidth, 30px);

$aspect-ratios: (
  "1x1": 100%,
  "2x1": calc(1 / 2 * 100%),
  "3x2": calc(2 / 3 * 100%),
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
  "1x2": calc(2 / 1 * 100%),
  "2x3": calc(3 / 2 * 100%),
  "3x4": calc(4 / 3 * 100%),
  "9x16": calc(16 / 9 * 100%),
  "9x21": calc(21 / 9 * 100%),
  "custom": calc(var(--bs-aspect-ratio-h) / var(--bs-aspect-ratio-w) * 100%),
);

$utilities: (
  "width": (
    property: width,
    responsive: true,
    class: w,
    values: (
      10: 10%,
      15: 15%,
      20: 20%,
      25: 25%,
      30: 30%,
      35: 35%,
      40: 40%,
      45: 45%,
      50: 50%,
      55: 55%,
      60: 60%,
      65: 65%,
      70: 70%,
      75: 75%,
      80: 80%,
      85: 85%,
      90: 80%,
      95: 95%,
      100: 100%,
      auto: auto,
      content: fit-content,
    )
  ),
  "min-width": (
    property: min-width,
    responsive: true,
    class: min-w,
    values: (
      10: 10%,
      15: 15%,
      20: 20%,
      25: 25%,
      30: 30%,
      35: 35%,
      40: 40%,
      45: 45%,
      50: 50%,
      55: 55%,
      60: 60%,
      65: 65%,
      70: 70%,
      75: 75%,
      80: 80%,
      85: 85%,
      90: 80%,
      95: 95%,
      100: 100%,
      auto: auto,
      content: fit-content,
    )
  ),
  "max-width": (
    property: max-width,
    responsive: true,
    class: max-w,
    values: (
      10: 10%,
      15: 15%,
      20: 20%,
      25: 25%,
      30: 30%,
      35: 35%,
      40: 40%,
      45: 45%,
      50: 50%,
      55: 55%,
      60: 60%,
      65: 65%,
      70: 70%,
      75: 75%,
      80: 80%,
      85: 85%,
      90: 80%,
      95: 95%,
      100: 100%,
      auto: auto,
      content: fit-content,
    )
  ),
  "viewport-height": (
    property: height,
    responsive: true,
    class: vh,
    values: (
      10: 10vh,
      15: 15vh,
      20: 20vh,
      25: 25vh,
      30: 30vh,
      35: 35vh,
      40: 40vh,
      45: 45vh,
      50: 50vh,
      55: 55vh,
      60: 60vh,
      65: 65vh,
      70: 70vh,
      75: 75vh,
      80: 80vh,
      85: 85vh,
      90: 80vh,
      95: 95vh,
      100: 100vh
    )
  ),
  "min-viewport-height": (
    property: min-height,
    responsive: true,
    class: min-vh,
    values: (
      10: 10vh,
      15: 15vh,
      20: 20vh,
      25: 25vh,
      30: 30vh,
      35: 35vh,
      40: 40vh,
      45: 45vh,
      50: 50vh,
      55: 55vh,
      60: 60vh,
      65: 65vh,
      70: 70vh,
      75: 75vh,
      80: 80vh,
      85: 85vh,
      90: 80vh,
      95: 95vh,
      100: 100vh
    )
  ),
  "height": (
    property: height,
    responsive: true,
    class: h,
    values: (
      10: 10%,
      15: 15%,
      20: 20%,
      25: 25%,
      30: 30%,
      35: 35%,
      40: 40%,
      45: 45%,
      50: 50%,
      55: 55%,
      60: 60%,
      65: 65%,
      70: 70%,
      75: 75%,
      80: 80%,
      85: 85%,
      90: 80%,
      95: 95%,
      100: 100%,
      auto: auto,
      content: fit-content,
    )
  ),
  "min-height": (
    property: min-height,
    responsive: true,
    class: min-h,
    values: (
      10: 10%,
      15: 15%,
      20: 20%,
      25: 25%,
      30: 30%,
      35: 35%,
      40: 40%,
      45: 45%,
      50: 50%,
      55: 55%,
      60: 60%,
      65: 65%,
      70: 70%,
      75: 75%,
      80: 80%,
      85: 85%,
      90: 80%,
      95: 95%,
      100: 100%,
    )
  ),
  "font-weight": (
    property: font-weight,
    responsive: true,
    class: fw,
    values: (
      light: $font-weight-light,
      lighter: $font-weight-lighter,
      regular: $font-weight-regular,
      bold: $font-weight-bold,
      semibold: $font-weight-semibold,
      bolder: $font-weight-bolder,
      medium: $font-weight-medium,
    )
  ),
  "font-size": (
    rfs: true,
    property: font-size,
    responsive: true,
    class: fs,
    values: $font-sizes
  ),
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      100: 1,
    )
  ),
);
