.review-quote {
  blockquote,
  .blockquote {
    font-weight: $font-weight-regular;
    font-family: $font-family-sans;
    quotes: "“" "”" "‘" "’";
    position: relative;
    padding-left: 0.5em;
    font-size: 1.25rem;

    &::before {
      content: open-quote;
      position: absolute;
      top: 0;
      left: 0;
    }

    &::after {
      content: close-quote;
    }
  }

  .blockquote-footer {
    padding-left: 0.5em;

    &::before {
      display: none;
    }

    .review_cite-name {
      font-family: $font-family-serif;
    }

    .review_cite-title {
      font-family: $font-family-sans;
      font-size: 0.5em;
      font-weight: $font-weight-medium;
    }
  }
}

.page_intro-holder {
  background-attachment: fixed;
  background-position: right;
  background-size: 50% auto;
  background-repeat: no-repeat;

  @include media-breakpoint-down(md) {
    background-image: unset !important;
  }

  @supports (-webkit-touch-callout: none) {
    background-attachment: scroll;
  }

  .intro_copy-holder {
    max-width: 1050px;
    padding-right: 50px;
    width: 100%;

    @include media-breakpoint-down(md) {
      padding-right: 1rem;
    }
  }

  .intro_heading-holder {
    max-width: 1050px;
    padding-right: 50px;
    width: 100%;

    @include media-breakpoint-down(md) {
      padding-left: 1rem;
      padding-right: 2rem;
    }
  }

  .intro_heading-sizer {
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  .page_intro-content_heading {
    @include media-breakpoint-up(md) {
      background-image: unset !important;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: flex-end !important;
      padding-left: 0;
      padding-right: 10% !important;
      background-size: 90% auto;
      background-position: right top;
      padding-top: 5vw;
      background-repeat: no-repeat;
      min-height: 92vw;

      @media (orientation: landscape) {
        min-height: 92vh;
      }
    }
  }
}

.page_splash {
  height: auto;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include media-breakpoint-down(md) {
    min-height: unset !important;
    padding-top: 2rem !important;
    background-attachment: scroll !important;
    background-size: cover !important;
  }

  .page_splash-container {
    height: auto;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 4rem;

    @include media-breakpoint-down(md) {
      padding-bottom: 2rem !important;

      h1 {
        min-height: calc(3em * 1.2);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
  }
}

.page_review {
  position: relative;
  background-image: var(--review-bg);

  .page_review-heading {
    padding-left: 0.5em;
  }

  &.review_dark {
    --rg-color: rgba(0, 0, 0, 0.6);

    .page_review-heading,
    .review-quote,
    .blockquote-footer {
      color: $white;
    }
  }

  &.review_light {
    --rg-color: rgba(255, 255, 255, 0.6);

    .page_review-heading,
    .review-quote,
    .blockquote-footer {
      color: $black;
    }
  }

  &.review_left {
    --rg-dir: to right;

    & > .row {
      justify-content: start;
    }
  }

  &.review_right {
    --rg-dir: to left;

    & > .row {
      justify-content: flex-end;
    }
  }

  &.review_overlay {
    --review-gradient: linear-gradient(var(--rg-dir), var(--rg-color) 0%, transparent 60%);

    background-image: var(--review-gradient), var(--review-bg);
  }

  &.review_primary-heading {
    .page_review-heading {
      color: $primary !important;
    }
  }
}

.wpcf7-form {
  &.sent {
    position: relative;

    > div:not(.wpcf7-response-output) {
      visibility: hidden;
    }

    .wpcf7-response-output {
      border-width: 0;
      padding: 0;
      margin-left: 0;
      margin-right: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      #footer_signup & {
        color: #fff;
      }

      #news_cta-form & {
        font-size: 1.5 * $font-size-base;
      }

      #contact_intro-form & {
        font-size: 1.5 * $font-size-base;
      }
    }
  }
}

.page_news-heading {
  br {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}
