header#site-header {
  #navbar-links {
    .list-group-item-action {
      white-space: nowrap;
      font-weight: $font-weight-regular;
      color: $brand-darkGrey;

      &:hover {
        color: $secondary;
      }
    }
  }

  #navbar-search {
    padding-top: 3px;

    .search-field {
      width: 25px;
      transition: width 1s;
      border-bottom-color: transparent;

      &.active,
      &:active,
      &:focus,
      &:hover {
        width: 300px;
        border-bottom-color: $brand-darkGrey;
      }
    }

    &.active,
    &:focus,
    &:hover,
    &:active {
      .search-field {
        width: 300px;
        border-bottom-color: $brand-darkGrey;
      }
    }
  }

  .navbar-toggler {
    width: 35px;
    height: 35px;

    .navbar-toggler-inner {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        transition: opacity 1s;

        &.inactive {
          opacity: 1;
        }

        &.active {
          opacity: 0;
        }
      }

      &:hover {
        .inactive {
          opacity: 0;
        }

        .active {
          opacity: 1;
        }
      }
    }
  }

  .ada-toggler {
    width: 35px;
    height: 35px;
    border: unset;
    padding: 0;
    background: transparent;
    transform: translateX(-5px);

    &:hover {
      border-color: $brand-orange;
    }

    .ada-toggler-inner {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      border: 2px solid $brand-lightBlue;
      border-radius: 50%;
      transition: all 50ms;

      img {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        transition: opacity 50ms;
        width: calc(100% - 10px);
        height: calc(100% - 10px);

        &.inactive {
          opacity: 1;
        }

        &.active {
          opacity: 0;
        }
      }

      &:hover {
        border-color: $brand-orange;

        .inactive {
          opacity: 0;
        }

        .active {
          opacity: 1;
        }
      }
    }
  }

  .offcanvas-header {
    .btn-close {
      background: unset !important;
      padding: 0;
      border-radius: 0;
      opacity: 1;
      width: 35px;
      height: 35px;
      margin: 0 !important;

      .navbar-close-inner {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;

        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          transition: opacity 1s;

          &.inactive {
            opacity: 1;
          }

          &.active {
            opacity: 0;
          }
        }

        &:hover {
          .inactive {
            opacity: 0;
          }

          .active {
            opacity: 1;
          }
        }
      }
    }
  }

  #offcanvas-menu {
    max-height: calc(100vh - 120px);
    overflow: auto;
  }

  #offcanvas_main-nav {
    .nav-item {
      margin-bottom: 0.4em;

      .nav-link {
        font-family: $font-family-serif;
        color: $primary;
        position: relative;
        font-weight: $font-weight-light;
        width: fit-content;
        padding-top: 0;
        padding-bottom: 3px;

        @include font-size($nav-font-size);

        span {
          transition: opacity 1s;

          &.inactive {
            opacity: 1;
          }

          &.active {
            opacity: 0;
            font-style: italic;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        &::after {
          content: " ";
          height: 2px;
          bottom: 0;
          left: 0;
          width: 0;
          background-color: $secondary;
          position: absolute;
          display: block;
          transition: width 1s;
        }

        &:hover {
          color: $secondary;

          span {
            &.active {
              opacity: 1;
            }

            &.inactive {
              opacity: 0;
            }
          }

          &::after {
            width: 100%;
          }
        }
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 0 !important;
      }
    }
  }

  #offcanvas-links {
    .list-group-item {
      color: $primary;

      &:hover {
        color: $secondary;
      }
    }
  }

  .navbar-right,
  .header-navbar-right {
    min-width: 100px;
  }

  .header-brand-logo {
    @include media-breakpoint-down(md) {
      max-height: 40px !important;
      width: auto !important;
    }
  }
}

.offcanvas-body {
  @include media-breakpoint-down(md) {
    @media (orientation: landscape) {
      flex-direction: row !important;
      justify-content: space-between !important;
    }
  }
}
