.page_partners-grid {
  .logo-block {
    transition: 25ms all;

    @include media-breakpoint-up(md) {
      filter: grayscale(1);
    }

    &:hover {
      filter: grayscale(0);
    }

    .partner-logo {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
