footer#site-footer {
  #footer-pattern {
    background-position: left top;
    background-repeat: repeat;
    background-size: 199px 70px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
  }

  #footer_heading {
    font-variation-settings: $font-var;
  }

  .footer-sep {
    background-color: $white;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0.5rem 1rem 0;
    border-radius: 100%;

    @include media-breakpoint-down(md) {
      width: 50px;
      height: 5px;
      margin: 0.75rem 0.5rem;
      border-radius: 0;
    }
  }

  .footer_brand {
    max-width: 300px;
  }

  .footer_legal_link {
    a:hover,
    a:focus,
    a:active {
      text-decoration: none !important;
      color: $brand-lightBlue !important;
    }
  }

  .list-group {
    .list-group-item-action {
      font-family: $font-family-sans;

      &:hover,
      &:active,
      &:focus {
        color: $brand-lightBlue !important;
        text-decoration: underline !important;
      }
    }
  }

  #footer_contact {
    address {
      font-weight: $font-weight-medium;
      font-size: 1.125rem;
      font-family: $font-family-sans;
      line-height: 1.5em;
    }

    img {
      height: 30px;
      width: auto;
    }

    a:hover,
    a:focus,
    a:active {
      text-decoration: none !important;
      color: $brand-lightBlue !important;
    }
  }

  #footer_tenderling {
    .tenderling-link {
      text-decoration: none !important;

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline !important;
        color: $brand-lightBlue !important;
      }
    }
  }

  form {
    input {
      color: $black;
      font-size: 0.8rem;
      font-weight: 500;
      height: 30px;
      padding: 5px;
      width: 100%;

      &::placeholder {
        text-transform: uppercase;
        color: $black;
        font-weight: 500;
      }
    }
  }

  #footer_logos {
    img {
      height: 20px;
      width: auto;
    }
  }
}
