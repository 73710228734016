#news_spotlight {
  background: rgb(238, 238, 238); /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, rgba(238, 238, 238, 1) 0%, rgba(204, 204, 204, 1) 100%);
  background: -webkit-radial-gradient(center, ellipse cover, rgba(238, 238, 238, 1) 0%, rgba(204, 204, 204, 1) 100%);
  background: radial-gradient(ellipse at center, rgba(238, 238, 238, 1) 0%, rgba(204, 204, 204, 1) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#eeeeee", endColorstr="#cccccc", GradientType=1);
}

#news_cta-form {
  input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid $brand-lightBlue;
    font-variation-settings: $font-var;
    font-family: $font-family-serif;
    font-weight: $font-weight-regular;
    color: $primary;
    font-size: 1.5rem;
    font-style: italic;
    max-width: 100%;

    &::placeholder {
      color: $brand-lightBlue;
    }
  }
}
