#contact_intro-form {
  input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid $brand-lightBlue;
    font-variation-settings: $font-var;
    font-family: $font-family-serif;
    font-weight: $font-weight-regular;
    color: $primary;
    font-size: 1.5rem;
    font-style: italic;
    max-width: 100%;

    &::placeholder {
      color: $brand-lightBlue;
    }
  }

  textarea {
    background: transparent;
    border: 1px solid $brand-lightBlue;
    font-variation-settings: $font-var;
    font-family: $font-family-serif;
    font-weight: $font-weight-regular;
    color: $primary;
    font-size: 1.5rem;
    font-style: italic;
    max-height: 165px;
    padding: 10px;
    max-width: 100%;

    &::placeholder {
      color: $brand-lightBlue;
    }
  }

  .wpcf7-checkbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 40em;

    .wpcf7-list-item {
      width: 48%;
      margin-left: 0;
      cursor: pointer;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      .wpcf7-list-item-label {
        color: $primary;
        font-variation-settings: $font-var;
        font-family: $font-family-serif;
        font-weight: $font-weight-regular;
        font-size: 1.5rem;
        font-style: italic;
        padding-left: 10px;
      }

      input[type=checkbox] {
        width: 20px;
        height: 20px;
      }
    }
  }
}

#contactMap {
  min-height: 600px;

  @include media-breakpoint-down(md) {
    @media (orientation: landscape) {
      min-height: calc(100vh + 300px);
    }

    @media (orientation: portrait) {
      min-height: calc(100vw + 300px);
    }
  }
}

#contact_map-box {
  max-width: 575px;

  @include media-breakpoint-down(md) {
    max-width: 100%;
  }
}

#contactMapData {
  @include media-breakpoint-down(md) {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}
