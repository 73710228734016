body.home {
  /**
  .navbar-brand {
    opacity: 0 !important;

    @include media-breakpoint-down(md) {
      opacity: 1 !important;
    }
  }
  **/

  #site-header {
    nav {
      background-color: transparent !important;
    }
  }

  &.splash-inactive {
    .navbar-brand {
      opacity: 1 !important;
    }

    #site-header {
      nav {
        background-color: $white !important;
      }
    }
  }

  #page-content {
    padding-top: 0 !important;
  }

  .page_splash {
    height: 100vh;
    padding-top: 150px !important;

    @include media-breakpoint-down(md) {
      min-height: unset !important;
      height: auto !important;
    }

    .page_splash-container {
      padding-bottom: 1rem;
      overflow: hidden;

      #splash-brand_holder {
        opacity: 0 !important;
        height: 1px !important;
        margin-top: 0 !important;

        /**
        margin-top: 8vh;

        @include media-breakpoint-up(xxxl) {
          margin-top: 15vh;
        }

        @include media-breakpoint-down(xl) {
          margin-top: 4vh;
        }

        @include media-breakpoint-down(md) {
          opacity: 0 !important;
          height: 1px !important;
          margin-top: 0 !important;
        }
        **/
      }
    }
  }
}

.stats_item-number {
  color: $brand-lightBlue;
  font-size: 9.375rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-down(lg) {
    font-size: 6.375rem;
  }

  @include media-breakpoint-down(md) {
    font-size: rfs-fluid-value(9.375rem);
  }
}

.stats_item-plus {
  position: relative;
  width: 0.3em;
  height: 0.3em;
  margin-left: 10px;

  &::before,
  &::after {
    content: " ";
    position: absolute;
    background-color: $brand-lightBlue;
  }

  &::before {
    width: 100%;
    height: 2px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &::after {
    width: 2px;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
}

#home_communities-carousel {
  padding: 0 10% !important;

  @include media-breakpoint-down(md) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .slick-dots {
    padding-right: 0;
    align-items: flex-end;
    justify-content: flex-end;

    @include media-breakpoint-down(md) {
      padding-left: 5%;
      padding-right: 5%;
      align-items: center;
      justify-content: center;
    }
  }

  .community-heading {
    small {
      display: block;
      font-size: 0.5em;
    }
  }

  .btn-end {
    &::after {
      @include media-breakpoint-down(md) {
        right: unset !important;
        left: 0;
      }
    }
  }
}

#home_services-body {
  background-attachment: fixed;
  background-position: right 75%;
  background-size: 60% auto;
  background-repeat: no-repeat;

  @supports (-webkit-touch-callout: none) {
    background-attachment: scroll;
  }

  @include media-breakpoint-down(md) {
    background-image: unset !important;
  }
}

#home_services-body_top {
  background-attachment: fixed;
  background-size: 33% auto;
  background-repeat: no-repeat;
  background-position: 2rem center;

  @supports (-webkit-touch-callout: none) {
    background-attachment: scroll;
  }

  @include media-breakpoint-down(md) {
    background-image: unset !important;
    padding: 0 !important;
  }
}

#home_services-body_carousel {
  position: relative;
  padding-right: 5rem;

  .services-carousel-item_holder {
    max-width: 650px;
  }

  @include media-breakpoint-down(md) {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .slick-dots {
    position: absolute;
    right: 0;
    top: 0;
    left: unset;
    width: 5rem;
    height: 100%;
    margin-bottom: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(md) {
      position: relative;
      width: 100%;
      height: auto;
      margin-bottom: 1rem;
      flex-direction: row;
    }
  }
}

#home_services-body_review {
  background-color: rgba(239, 239, 239, 0.8);
  transform: translateY(-50%);

  @include media-breakpoint-down(md) {
    transform: unset !important;
  }
}

#home_services-body_footer {
  margin-top: 60vh;
  padding-bottom: 10vh;
  z-index: 2;
  background-attachment: fixed;
  background-size: 42% auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: relative;

  @supports (-webkit-touch-callout: none) {
    background-attachment: scroll;
  }

  @include media-breakpoint-down(lg) {
    padding-bottom: 0;
  }

  @include media-breakpoint-down(md) {
    margin-top: 0;
    background-size: contain;
    background-attachment: scroll;

    @media (orientation: landscape) {
      padding-bottom: 25vw;
    }

    @media (orientation: portrait) {
      padding-bottom: 25vh;
    }
  }

  &::before {
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    height: 15vh;
    content: " ";
    background-color: $white;

    @include media-breakpoint-down(lg) {
      height: 5vh;
    }

    @include media-breakpoint-down(md) {
      @media (orientation: landscape) {
        height: 1vw;
      }

      @media (orientation: portrait) {
        height: 1vh;
      }
    }
  }
}

#home_news-heading {
  color: $primary !important;
}

#home_services-carousel_holder {
  @include media-breakpoint-up(md) {
    transform: translateY(-25%);
  }
}
