@import "common/variables";

/** Import everything from autoload */
@import "./autoload/_animate.scss"; @import "./autoload/_bootstrap.scss";

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";

// Import Slick
@import "~@accessible360/accessible-slick/slick/slick.scss";
@import "~@accessible360/accessible-slick/slick/slick-theme.scss";

/** Import theme styles */
@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/pages/home";
@import "layouts/pages/communities";
@import "layouts/pages/news";
@import "layouts/pages/about";
@import "layouts/pages/services";
@import "layouts/pages/contact";
@import "layouts/pages/reviews";
@import "layouts/pages/partners";
