:root {
  --animate-delay: 500ms !important;
  --carouselIndicatorWidth: 30px;
  --carouselIndicatorHeight: 3px;
  --bs-font-sans-serif: $font-family-sans;
  --bs-font-serif: $font-family-serif;
  --btn-color: var(--bs-primary);
  --btn-hover: var(--bs-secondary);
  --scrollbarWidth: 0;

  overflow-x: hidden;
}

/* way the hell off screen */
.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

html {
  font-size: 20px;
  line-height: 1.5em;
  scroll-padding-top: 100px;
}

ol,
ul {
  padding-left: 1.3em;
}

li {
  margin-bottom: 0.8em;
}

body.modal-open::-webkit-scrollbar {
  display: none;
}

.fit-cover {
  object-fit: cover;
}

.fit-contain {
  object-fit: contain;
}

.bg-blend-overlay {
  background-blend-mode: overlay;
}

.bg-blend-multiply {
  background-blend-mode: multiply;
}

.container-fluid {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down(md) {
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &.container-full {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.bg-transparent-white {
  background-color: rgba(255, 255, 255, 0.8);
}

.bg-darkBlue {
  background-color: $brand-darkBlue;
}

.bg-grey {
  background-color: $brand-grey;
}

.text-teal {
  color: $teal !important;
}

.text-whiteBlue {
  color: $brand-whiteBlue !important;
}

.text-darkGrey {
  color: $brand-darkGrey !important;
}

.text-serif {
  font-variation-settings: $font-var;
  font-family: $font-family-serif;
  font-weight: $font-weight-regular;
}

.text-sans {
  font-family: $font-family-sans;
}

.parallax {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @supports (-webkit-touch-callout: none) {
    background-attachment: scroll;
  }

  @include media-breakpoint-down(md) {
    background-attachment: scroll;
  }
}

.btn {
  font-variation-settings: $font-var;
  font-style: italic;
  position: relative;
  background-color: transparent;
  color: var(--btn-color, $primary);

  &::after {
    content: " ";
    width: 50%;
    max-width: 90px;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    transition: all 1s;
    background-color: var(--btn-color, $primary);
  }

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: var(--btn-hover, $secondary);

    &::after {
      max-width: 100%;
      width: 100%;
      background-color: var(--btn-hover, $secondary);
    }
  }

  &.btn-secondary {
    --btn-color: var(--bs-secondary);
    --btn-hover: var(--bs-primary);
  }

  &.btn-white {
    --btn-color: var(--bs-white);
    --btn-hover: var(--bs-secondary);
  }

  &.btn-end {
    &::after {
      left: unset;
      right: 0;
    }
  }

  &.btn-link {
    &::after {
      display: none !important;
    }
  }
}

h1,
.h1 {
  letter-spacing: 0.015em;

  @include media-breakpoint-down(xxl) {
    font-size: $h1-font-size * 0.875;
  }

  @include media-breakpoint-down(xl) {
    font-size: $h1-font-size * 0.5;
  }

  @include media-breakpoint-down(lg) {
    @include font-size($h1-font-size * 0.3);
  }
}

h2 {
  @include media-breakpoint-down(md) {
    @include font-size($h2-font-size * 0.75);
  }
}

h5,
.h5 {
  font-weight: $font-weight-medium;
  font-family: $font-family-sans;
}

h6,
.h6 {
  font-weight: $font-weight-bold;
  font-family: $font-family-sans;
  letter-spacing: 0.09em;
}

p,
.p,
body,
ul,
ol,
li {
  font-weight: $font-weight-regular;
  font-family: $font-family-sans;
}

#page-content {
  padding-top: 80px;
}

.search-form-inner {
  position: relative;

  .btn {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);

    .search-button-inner {
      position: relative;
      width: 100%;
      height: 100%;
      display: block;

      img {
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity 1s;
      }
    }

    .active {
      opacity: 0;
    }

    .inactive {
      opacity: 1;
    }

    &:hover {
      .active {
        opacity: 1;
      }

      .inactive {
        opacity: 0;
      }
    }
  }

  .search-field {
    width: 300px;
    padding-left: 40px;
    border: 0;
    border-bottom: 2px solid $brand-darkGrey;
    height: 36px;
    background-color: transparent;
  }
}

.animate_me {
  &:not(.animate__animated) {
    opacity: 0;
  }
}

.slick-pause-icon,
.slick-play-icon {
  display: none !important;
}

.slick-dots {
  --slick-dots-color: $primary;
  --slick-dots-size: 12px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &.slick-dots-white {
    --slick-dots-color: $white;
  }

  &.slick-dots-secondary {
    --slick-dots-color: $secondary;
  }

  li {
    button {
      .slick-dot-icon {
        opacity: 1;

        &::before {
          display: none;
        }

        circle {
          stroke: var(--slick-dots-color);
          fill: transparent;
        }
      }
    }

    &.slick-active {
      button {
        .slick-dot-icon {
          opacity: 1;

          &::before {
            display: none;
          }

          circle {
            stroke: var(--slick-dots-color);
            fill: var(--slick-dots-color);
          }
        }
      }
    }
  }
}

.search-btn-close {
  background: unset !important;
  padding: 0;
  border-radius: 0;
  opacity: 1;
  width: 35px;
  height: 35px;

  .btn-close-inner {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    .btn-close-inner-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      transition: opacity 1s;

      &.inactive {
        opacity: 1;
      }

      &.active {
        opacity: 0;
      }
    }

    &:hover {
      .inactive {
        opacity: 0;
      }

      .active {
        opacity: 1;
      }
    }
  }
}

.ada-skip-link {
  position: absolute;
  top: -1000em;
}

.ada-skip-link:focus {
  left: 50px;
  top: 50px;
  height: auto;
  width: 200px;
  display: block;
  font-size: 1rem;
  font-weight: $font-weight-semibold;
  text-align: center;
  padding: 15px 23px 14px;
  background-color: $primary;
  color: $white;
  z-index: 100000;
  line-height: normal;
  text-decoration: none;
  box-shadow: 0 0 2px 2px rgb(0 0 0 / 60%);
}

.cky-consent-container {
  .cky-consent-bar {
    .cky-title {
      font-variation-settings: $font-var;
      font-family: $font-family-serif;
      font-weight: $font-weight-regular;
      font-size: $quote-font-size;
      line-height: 1.5;
    }

    .cky-notice-des {
      max-width: 900px;

      p {
        font-size: $body-font-size;
      }
    }

    .cky-btn {
      font-variation-settings: $font-var;
      font-style: italic;
      position: relative;
      font-family: $font-family-serif;
      font-weight: $font-weight-regular;
      font-size: $font-size-base;
      padding-left: $btn-padding-x;
      padding-right: $btn-padding-x;
      padding-top: $btn-padding-y;
      padding-bottom: $btn-padding-y;
      margin-right: 1em;
      line-height: 1.5;
      margin-bottom: 1em;
      text-align: left;
      border-width: 0 !important;

      &::before {
        content: " ";
        width: 50%;
        max-width: 90px;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        transition: all 1s;
        background-color: $brand-darkBlue;
      }

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        color: $brand-darkBlue;

        &::before {
          max-width: 100%;
          width: 100%;
          background-color: $brand-darkBlue;
        }
      }
    }
  }
}

.slick-carousel,
.slick-slider {
  .slick-arrow {
    position: absolute;
    display: block;
    height: 35px;
    width: 35px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    z-index: 1;

    .slick-next-icon,
    .slick-prev-icon {
      position: relative;
      display: block;
      width: 20px;
      height: 20px;
      transition: all 50ms;

      &::before {
        content: " " !important;
        width: 100%;
        display: block;
        position: absolute;
        height: 2px;
        top: 0;
        left: 0;
        opacity: 1;
        background-color: $white;
        box-shadow: 0.01px 0 5px rgba(0, 0, 0, 0.3);
      }

      &::after {
        content: " ";
        width: 2px;
        display: block;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $white;
        box-shadow: 0 0.01px 5px rgba(0, 0, 0, 0.3);
      }
    }

    &.slick-next {
      right: 5vw;

      .slick-next-icon {
        transform: rotate(135deg);
      }
    }

    &.slick-prev {
      left: 5vw;

      .slick-prev-icon {
        transform: rotate(-45deg);
      }
    }

    &:hover {
      .slick-prev-icon,
      .slick-next-icon {
        &::before,
        &::after {
          background-color: $secondary;
        }
      }
    }
  }
}

.social_profile {
  transition: all 50ms;

  .social-icon {
    transition: all 50ms;
  }

  &:hover {
    .social-icon {
      transform: translateY(-25%);
    }
  }
}
