#comSearchMap {
  .cluster-label {
    top: -5px;
    position: relative;
  }

  .mapBlock-right {
    @include media-breakpoint-down(md) {
      padding-bottom: 10px;

      .ratio-1x1 {
        --bs-aspect-ratio: 50%;
      }
    }
  }

  .mapBlock_info-heading {
    small {
      display: block;
    }
  }

  .gm-style .gm-style-iw-c {
    border-radius: 0 !important;
    padding-left: 0;
    padding-right: 0;
    padding-top: 15px;
  }

  .gm-ui-hover-effect {
    top: 20px !important;
    right: 20px !important;
    opacity: 1 !important;

    & > span {
      background-color: $white;
      width: 30px !important;
      height: 30px !important;
      transition: all 200ms;
      margin: 0 !important;
    }

    &:hover {
      & > span {
        background-color: $secondary;
      }
    }
  }
}

#communities_chart-wrapper {
  @include media-breakpoint-up(md) {
    &::before {
      content: " ";
      height: calc(100% - 1.5rem);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      opacity: 1;
      border-right: 2px solid #000;
    }

    &::after {
      content: " ";
      width: calc(100% - 1.5rem);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      opacity: 1;
      border-bottom: 2px solid #000;
    }
  }
}

#communities_chart-rowLabels {
  writing-mode: vertical-lr;

  #communities_chart-rowLabels_top,
  #communities_chart-rowLabels_bottom {
    transform: rotate(180deg);
  }
}

#communities_chart-table {
  .chart-block {
    transition: all 1s;

    img {
      opacity: 0;
      transition: all 1s;

      @include media-breakpoint-down(md) {
        opacity: 1;
      }
    }

    .block-info {
      background-color: transparent;
      transition: all 1s;

      @include media-breakpoint-down(md) {
        background-color: rgba(0, 61, 165, 0.9);
      }
    }

    .block-heading {
      color: $primary;
      transition: all 50ms;
      font-size: $h4-font-size;

      @include media-breakpoint-down(md) {
        color: $white;

        @include font-size($h4-font-size * 0.75);
      }
    }

    .block-details {
      display: none;
      transition: all 1s;

      @include media-breakpoint-down(md) {
        display: block;
      }
    }

    &:hover {
      img {
        opacity: 1;
      }

      .block-info {
        background-color: rgba(0, 61, 165, 0.9);

        @include media-breakpoint-down(md) {
          background-color: rgba(255, 255, 255, 0.9);
        }
      }

      .block-heading {
        color: $white;

        @include media-breakpoint-down(md) {
          color: $primary;
        }
      }

      .block-details {
        display: block;
      }
    }
  }
}

#communities_search-scroller {
  .scroller-content {
    height: 70vh;

    @include media-breakpoint-down(md) {
      @media (orientation: landscape) {
        height: 70vw;
      }
    }
  }

  .scroller-block {
    .scroller-block_front {
      .scroller_construction-label {
        position: absolute;
        top: 20px;
        left: 0;
        max-width: 90%;
        color: $primary;
        width: fit-content;
        height: fit-content;
        min-height: 33px;
        padding: 0 10px;
        background-size: contain;
        padding-right: 10%;
        background-repeat: no-repeat;
      }
    }

    .scroller-block_back {
      background-color: rgba(255, 255, 255, 0.9);
      transition: 2s;
      height: 0;
      top: 0;
      overflow: hidden;

      .scroll-block_info-heading {
        font-size: 1.4rem;

        small {
          display: block;
        }
      }
    }

    &:hover {
      .scroller-block_back {
        height: 100%;
      }
    }
  }

  .scroller-style1 {
    width: calc(28vw - 0.5rem - (var(--scrollbarWidth) / 4));

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .scroller-style2 {
    width: calc(32vw - 0.5rem - (var(--scrollbarWidth) / 4));

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .scroller-style3 {
    width: calc(18vw - 0.5rem - (var(--scrollbarWidth) / 4));

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .scroller-style4 {
    width: calc(22vw - 0.5rem - (var(--scrollbarWidth) / 4));

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .scroller-style2 .scroller-block {
    height: 100%;
  }

  .scroller-style3 .scroller-block {
    height: calc(100% / 3);
  }

  .scroller-style1 .scroller-block:first-child {
    height: calc(100% / 3);
  }

  .scroller-style4 .scroller-block:first-child {
    height: calc(200% / 3);
  }

  .scroller-style1 .scroller-block:last-child {
    height: calc(200% / 3);
  }

  .scroller-style4 .scroller-block:last-child {
    height: calc(100% / 3);
  }

  .scroller-style3 .scroller-block:last-child:first-child {
    height: 100% !important;
  }

  .scroller-style3 .scroller-block:last-child:nth-child(2) {
    height: calc(200% / 3);
  }

  .scroller-style1 .scroller-block:first-child:last-child {
    height: 100% !important;
  }

  .scroller-style4 .scroller-block:first-child:last-child {
    height: 100% !important;
  }

  .slick-dots {
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
  }
}

#communities_search-switcher {
  .btn {
    &:first-child {
      border-right: 0.25px solid $white;
    }

    &:last-child {
      border-left: 0.25px solid $white;
    }

    &.active {
      font-weight: $font-weight-bold;

      &:hover {
        color: $white;
      }
    }
  }
}

#communities_search-body {
  .inactive {
    display: none;
  }

  .active {
    display: block;
  }

  &.no-results {
    & > .inactive,
    & > .active {
      display: none !important;
    }

    &::before {
      content: "Sorry! No results match your criteria. Please adjust and try your search again.";
      text-align: center;
      font-family: $font-family-sans;
      display: block;
      width: 100%;
      color: $white;
      padding: 1rem;
    }
  }
}

#communities_search-filters {
  .dropdown-menu {
    .dropdown-item {
      .active {
        opacity: 0;
        transition: 1s opacity;
      }

      .inactive {
        opacity: 1;
        transition: 1s opacity;
      }

      &:hover,
      &.active {
        cursor: pointer;
        background-color: transparent;

        .active {
          opacity: 1;
        }

        .inactive {
          opacity: 0;
        }
      }
    }
  }

  .dropdown-toggle {
    .filter-icon {
      .icon-img {
        transition: all 250ms;
      }
    }

    &.show {
      .filter-icon {
        .icon-img {
          transform: scaleY(-1);
        }
      }
    }

    &:hover,
    &:active,
    &:focus {
      color: $white;
    }
  }

  #communities_search-filters_more {
    &::placeholder {
      color: $brand-whiteBlue;
    }
  }
}

#communityModal {
  .modal-content {
    background-color: rgba(255, 255, 255, 0.95);
  }

  .modal-body {
    overflow-x: hidden;
  }

  .btn-close {
    background: unset !important;
    padding: 0;
    border-radius: 0;
    opacity: 1;
    width: 35px;
    height: 35px;

    .btn-close-inner {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        transition: opacity 1s;

        &.inactive {
          opacity: 1;
        }

        &.active {
          opacity: 0;
        }
      }

      &:hover {
        .inactive {
          opacity: 0;
        }

        .active {
          opacity: 1;
        }
      }
    }
  }

  .modal-link {
    svg {
      path {
        transition: fill 1s;
      }
    }

    &:hover {
      svg {
        path {
          fill: $secondary !important;
        }
      }
    }
  }

  .modal_construction-label {
    position: absolute;
    top: 20px;
    left: 0;
    max-width: 90%;
    color: $primary;
    width: fit-content;
    height: fit-content;
    min-height: 33px;
    padding: 0 10px;
    background-size: contain;
    padding-right: 10%;
    background-repeat: no-repeat;
  }
}
