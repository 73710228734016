#services_features {
  .services_features-block {
    @include media-breakpoint-up(md) {
      &:nth-child(odd) {
        padding-left: 5vw;
      }

      &:nth-child(even) {
        padding-right: 5vw;

        .content-col {
          order: 2;
        }
      }
    }

    @include media-breakpoint-down(md) {
      &:last-child {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
      }
    }
  }
}
